@font-face {
  font-family: 'bold';
  src: local('Stolzl-Bold'), url(./assets/font/Stolzl/Stolzl-Medium.ttf) format('truetype');
}
@font-face {
  font-family: 'medium';
  src: local('Stolzl-Medium'), url(./assets/font/Stolzl/Stolzl-Medium.ttf) format('truetype');
}
@font-face {
  font-family: 'light';
  src: local('Stolzl-Light'), url(./assets/font/Stolzl/Stolzl-Light.ttf) format('truetype');
}
@font-face {
  font-family: 'regular';
  src: local('Stolzl-Regular'), url(./assets/font/Stolzl/Stolzl-Book.ttf) format('truetype');
}